import * as consts from 'Client/consts'

// Deprecated jQuery API
if (typeof $ !== 'undefined') {
  window.ImageMapPro.subscribe(action => {
    if (action.type == consts.HOOK_MAP_INIT) {
      $.imageMapProInitialized?.call(this, action.payload.map)
    }
    if (action.type == consts.HOOK_OBJECT_HIGHLIGHT) {
      $.imageMapProEventHighlightedShape?.call(this, action.payload.map, action.payload.object)
    }
    if (action.type == consts.HOOK_OBJECT_UNHIGHLIGHT) {
      $.imageMapProEventUnhighlightedShape?.call(this, action.payload.map, action.payload.object)
    }
    if (action.type == consts.HOOK_OBJECT_CLICK) {
      $.imageMapProEventClickedShape?.call(this, action.payload.map, action.payload.object)
    }
    if (action.type == consts.HOOK_TOOLTIP_SHOW) {
      $.imageMapProEventOpenedTooltip?.call(this, action.payload.map, action.payload.object)
    }
    if (action.type == consts.HOOK_TOOLTIP_HIDE) {
      $.imageMapProEventClosedTooltip?.call(this, action.payload.map, action.payload.object)
    }
    if (action.type == consts.HOOK_ARTBOARD_CHANGE) {
      $.imageMapProEventSwitchedFloor?.call(this, action.payload.map, action.payload.layer)
    }
  })
}