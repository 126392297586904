export const EVENT_GROUP_ITEMS = 'event group items'
export const EVENT_DELETE_ITEMS = 'event delete items'
export const EVENT_UNDO = 'event undo'
export const EVENT_REDO = 'event redo'
export const EVENT_OPEN_SVG_IMPORT_MODAL = 'event open svg import modal'
export const EVENT_OPEN_ICON_LIBRARY_MODAL = 'event open icon library modal'
export const EVENT_OPEN_EXPORT_MODAL = 'event open export modal'
export const EVENT_OPEN_IMPORT_MODAL = 'event open import modal'
export const EVENT_OPEN_OBJECT_DEFAULTS_MODAL = 'event open object defaults modal'
export const EVENT_OPEN_LOAD_MODAL = 'event open load modal'
export const EVENT_OPEN_NEW_MAP_MODAL = 'event open new map modal'
export const EVENT_OPEN_PUBLISH_MODAL = 'event open publish modal'
export const EVENT_FORM_CONTROL_UPDATE = 'event form control update'
export const EVENT_FORM_CONTROL_UPDATE_START = 'event form control update start'
export const EVENT_FORM_CONTROL_UPDATE_END = 'event form control update end'
export const EVENT_OBJECT_MOVE = 'event object move'
export const EVENT_OBJECT_SCALE = 'event object scale'
export const EVENT_OBJECT_EDIT_POLY = 'event object edit poly'
export const EVENT_OBJECT_TRANSFORM_START = 'event object transform start'
export const EVENT_OBJECT_TRANSFORM_END = 'event object transform end'
export const EVENT_CANVAS_DRAG = 'event canvas drag'
export const EVENT_CANVAS_RESET = 'event canvas reset'
export const EVENT_CANVAS_ZOOM_START = 'event canvas zoom start'
export const EVENT_CANVAS_ZOOM_END = 'event canvas zoom end'
export const EVENT_CONTEXT_ZOOM_IN = 'event canvas zoom in'
export const EVENT_CONTEXT_ZOOM_OUT = 'event canvas zoom out'
export const EVENT_OBJECT_QUICK_DRAG = 'EVENT_OBJECT_QUICK_DRAG'
export const EVENT_BUG_REPORT = 'EVENT_BUG_REPORT'
export const EVENT_EXECUTE_ACTION_MODAL = 'EVENT_EXECUTE_ACTION_MODAL'

export const CONTENT_BLOCK_PARAGRAPH = 'Paragraph'
export const CONTENT_BLOCK_HEADING = 'Heading'
export const CONTENT_BLOCK_BUTTON = 'Button'
export const CONTENT_BLOCK_IMAGE = 'Image'
export const CONTENT_BLOCK_VIDEO = 'Video'
export const CONTENT_BLOCK_YOUTUBE = 'YouTube'

export const TOOL_SELECT = 'tool-1'
export const TOOL_SPOT = 'tool-2'
export const TOOL_RECT = 'tool-3'
export const TOOL_OVAL = 'tool-4'
export const TOOL_POLY = 'tool-5'
export const TOOL_TEXT = 'tool-6'
export const TOOL_ZOOM_IN = 'tool-7'
export const TOOL_ZOOM_OUT = 'tool-8'
export const TOOL_DRAG = 'tool-9'

export const OBJECT_ARTBOARD = 'artboard'
export const OBJECT_SPOT = 'spot'
export const OBJECT_RECT = 'rect'
export const OBJECT_OVAL = 'oval'
export const OBJECT_POLY = 'poly'
export const OBJECT_TEXT = 'text'
export const OBJECT_SVG = 'svg'
export const OBJECT_SVG_SINGLE = 'svg-single'
export const OBJECT_GROUP = 'group'

export const CANVAS_BACKGROUND = 'CANVAS_BACKGROUND'
export const CANVAS_OBJECT_ROOT = 'CANVAS_OBJECT_ROOT'
export const CANVAS_OBJECT_FIRST_POLY_POINT = 'CANVAS_OBJECT_FIRST_POLY_POINT'
export const MAIN_ROOT = 'MAIN_ROOT'

export const UI_OBJECT_LIST = 'UI_OBJECT_LIST'

export const CLIPBOARD_TYPE_OBJECTS = 'CLIPBOARD_TYPE_OBJECTS'
export const CLIPBOARD_TYPE_STYLES = 'CLIPBOARD_TYPE_STYLES'
export const CLIPBOARD_TYPE_TOOLTIP_STYLES = 'CLIPBOARD_TYPE_TOOLTIP_STYLES'
export const CLIPBOARD_TYPE_TOOLTIP_CONTENT = 'CLIPBOARD_TYPE_TOOLTIP_CONTENT'

export const SHORTCUT_SELECT = 'SHORTCUT_SELECT'
export const SHORTCUT_SPOT = 'SHORTCUT_SPOT'
export const SHORTCUT_RECT = 'SHORTCUT_RECT'
export const SHORTCUT_OVAL = 'SHORTCUT_OVAL'
export const SHORTCUT_POLY = 'SHORTCUT_POLY'
export const SHORTCUT_TEXT = 'SHORTCUT_TEXT'
export const SHORTCUT_PAN_START = 'SHORTCUT_PAN_START'
export const SHORTCUT_PAN_END = 'SHORTCUT_PAN_END'
export const SHORTCUT_ZOOM_IN = 'SHORTCUT_ZOOM_IN'
export const SHORTCUT_ZOOM_OUT = 'SHORTCUT_ZOOM_OUT'
export const SHORTCUT_WHEEL_ZOOM_IN = 'SHORTCUT_WHEEL_ZOOM_IN'
export const SHORTCUT_WHEEL_ZOOM_OUT = 'SHORTCUT_WHEEL_ZOOM_OUT'
export const SHORTCUT_ZOOM_RESET = 'SHORTCUT_ZOOM_RESET'
export const SHORTCUT_HIDE_OBJECT_LIST = 'SHORTCUT_HIDE_OBJECT_LIST'
export const SHORTCUT_HIDE_OBJECT_SETTINGS = 'SHORTCUT_HIDE_OBJECT_SETTINGS'
export const SHORTCUT_PREVIEW = 'SHORTCUT_PREVIEW'
export const SHORTCUT_DELETE = 'SHORTCUT_DELETE'
export const SHORTCUT_ENTER = 'SHORTCUT_ENTER'
export const SHORTCUT_ESC = 'SHORTCUT_ESC'